"use client";
import { clarity } from "clarity-js";
import { clarity as clarityTypeConst } from "clarity-js/types";
import { Config } from "clarity-js/types/core";
import { useEffect } from "react";

// Clarity doesn't export a type, it exports a const of type Clarity.
type Clarity = typeof clarityTypeConst;

// Utility functions
const sendData = async (payload: string) => {
  const headers = {};

  const response = await fetch("/analytics", {
    method: "POST",
    headers: headers,
    body: payload,
    credentials: "include",
  });

  if (!response.ok) {
    throw new Error(`${response.status} : ${response.statusText}`);
  }
};

export const claritySettings: Config = { projectId: "kx64wadpj7", track: false, content: true, upload: sendData };

const ClarityProvider = ({ children }) => {
  useEffect(() => {
    clarity.start(claritySettings);
    return () => {
      clarity.stop();
    };
  }, []);

  return children;
};

export default ClarityProvider;
