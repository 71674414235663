import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Switch, Typography } from "@mui/material";
import Link from "next/link";
import { useEffect } from "react";

interface Props {
  open: boolean;
  setOpenInfoModal: React.Dispatch<React.SetStateAction<boolean>>;
  acceptAnalytics: boolean;
  setAcceptAnalytics: React.Dispatch<React.SetStateAction<boolean>>;
  acceptAll: (w: any) => void;
  acceptSelected: (w: any) => void;
  decline: (w: any) => void;
}

const CookieInfoModal = ({ open, acceptAnalytics, setAcceptAnalytics, acceptAll, acceptSelected, decline, setOpenInfoModal }: Props) => {
  return (
    <Dialog open={open} maxWidth={"sm"}>
      <DialogTitle>Behandle innstillinger for informasjonskapsler</DialogTitle>
      <DialogContent sx={{ display: "flex", gap: "20px", flexDirection: "column" }}>
        <Typography>
          HappyDogs bruker informasjonskapsler for å tilpasse nettsiden etter brukernes opplevelse. Små tesktfiler blir lagret på enheten
          din, slik at innstillingene dine huskes for hver gang du logger inn. For å tilpasse HappyDogs og optimalisere nettsiden, bruker vi
          en tredjepart, Microsoft Clarity, til å analysere brukeraktivitet. Hvis du vil ha mer informasjon, kan du se punktet om
          informasjonskapsler i{" "}
          <Link href="../information/privacy">
            <Typography sx={{ textDecoration: "underline" }} color="primary">
              personvernerklæringen.
            </Typography>
          </Link>
          <br />
          <br />
          Ved å trykke "tillatt" gir du din tillatelse til disse formålene. Du kan trekke tilbake samtykket ditt ved å trykke på det lille
          ikonet nederst i venstre hjørnet av nettsiden.
        </Typography>
        <div>
          <Typography variant="subtitle1">Nødvendige </Typography>
          Vi bruker nødvendige informasjonskapsler til å utføre viktige nettstedfunksjoner. De brukes for eksempel til å logge deg på,
          forbedre ytelsen, rute trafikk mellom nettservere, finne ut skjermstørrelsen, fastslå innlastingstiden for sider, forbedre
          brukeropplevelsen og måle bruk. Disse informasjonskapslene er nødvendige for at nettsidene våre skal fungere.
        </div>
        <div>
          <Typography variant="subtitle1">Analyse </Typography>
          Vi lar tredjeparter bruke analyseinformasjonskapsler til å forstå hvordan du bruker nettstiden vår, slik at vi kan gjøre den
          bedre. De brukes for eksempel til å samle inn informasjon hvilke sider du besøker og om hvor mange klikk du trenger for å utføre
          en oppgave.{" "}
        </div>
      </DialogContent>
      <DialogActions sx={{ display: "flex", flexDirection: "column" }}>
        <Box display={"flex"}>
          <FormControlLabel control={<Switch disabled checked />} label="Nødvendige" />
          <FormControlLabel
            control={<Switch checked={acceptAnalytics} />}
            onChange={() => setAcceptAnalytics(!acceptAnalytics)}
            label="Analyse"
          />
        </Box>
        <Box>
          <Button variant="contained" onClick={() => acceptAll(window)}>
            Tillatt alle
          </Button>
          <Button color="secondary" onClick={() => acceptSelected(window)}>
            Tillatt valgte
          </Button>
          <Button color="secondary" onClick={() => decline(window)}>
            Avslå
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default CookieInfoModal;
