import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box, Container, Typography } from "@mui/material";
import { DrawerButton } from "../Dialog/CustomDrawer";

interface Props {
  bugReport: () => void;
  Suggestionreport: () => void;
  supportReport: () => void;
}

const FeedBackSelection = ({ bugReport, Suggestionreport, supportReport }: Props) => {
  return (
    <Box>
      <DrawerButton onClick={bugReport}>
        <Container maxWidth="md" sx={{ textAlign: "left", display: "flex", justifyContent: "space-between" }}>
          <Typography variant="subtitle2">Rapporter feil</Typography>
          <ArrowForwardIosIcon />
        </Container>
      </DrawerButton>
      <DrawerButton onClick={Suggestionreport}>
        <Container maxWidth="md" sx={{ textAlign: "left", display: "flex", justifyContent: "space-between" }}>
          <Typography variant="subtitle2">Forbedringsforslag</Typography>
          <ArrowForwardIosIcon />
        </Container>
      </DrawerButton>
      <DrawerButton onClick={supportReport}>
        <Container maxWidth="md" sx={{ textAlign: "left", display: "flex", justifyContent: "space-between" }}>
          <Typography variant="subtitle2">Support</Typography>
          <ArrowForwardIosIcon />
        </Container>
      </DrawerButton>
    </Box>
  );
};

export default FeedBackSelection;
