"use client";

import { useGetPrivateMemberDetailsEndpoint } from "./generated/happydogsComponents";
import { useSession } from "@clerk/nextjs";
import { useEffect } from "react";

export const WatchMemberId = () => {
  const { isSignedIn } = useSession();
  const { data: member, error } = useGetPrivateMemberDetailsEndpoint({}, { retry: true, enabled: isSignedIn, cacheTime: 300 });

  useEffect(() => {
    if (error) console.error("failed to load member!", error);

    if (!member) return;
    console.info("Member loaded", member.id);
  }, [member?.id, error]);
  return null;
};
