"use client";
import { BottomNavigation, BottomNavigationAction, SvgIcon } from "@mui/material";
import Paper from "@mui/material/Paper";
import Link from "next/link";
import { useSelectedLayoutSegments } from "next/navigation";
import { useEffect, useState } from "react";
import useMenuItems from "./CustomHooks/useMenuitems";
import { orange, tan } from "./Theme";

interface MenuProps {
  isBreeder?: boolean;
  hasBreederPage?: boolean;
}

const BottomNav = ({ isBreeder = false, hasBreederPage = false }: MenuProps) => {
  const [value, setValue] = useState<string | boolean>(false);

  const segments = useSelectedLayoutSegments();

  const menuItems = useMenuItems(isBreeder, hasBreederPage);

  useEffect(() => {
    setValue(() => {
      switch (segments[0]) {
        case "me":
          if (!segments[1]) {
            return false; // Should this be false instead? @Anne Skjaeveland (If a user is on the profile, this will put the tab under "Oppdrett", setting to false will remove the tab underline)
          } else {
            switch (segments[1]) {
              case "messages":
                return false;
              case "(protected)":
                return "/" + segments[0] + "/" + segments[2];
              default:
                return "/" + segments[0] + "/" + segments[1];
            }
          }
        case "admin":
          return false;
        case "search":
          return "/search?type=Breeder";
        default:
          return false;
      }
    });
  }, [segments]);

  return (
    <Paper
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        width: "100vw",
        height: "56px",
        paddingBottom: "4px",
        alignItems: "center",
        textAlign: "center",
        display: { xs: "flex", md: "none" },
      }}
    >
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        sx={{ width: "100%", height: "100%", gap: "8px" }}
      >
        {menuItems.map(({ label, href, options, icon }, index) => (
          <BottomNavigationAction
            key={href}
            label={label}
            href={href}
            sx={{
              color: tan[300],
              alignSelf: "center",
              gap: "2px",
              height: "100%",
              borderTop: href === value ? `3px solid ${orange[100]}` : "none",
              whiteSpace: "nowrap",
            }}
            value={href}
            color={value === href ? "primary" : "secondary"}
            LinkComponent={Link}
            icon={
              <SvgIcon
                color={value === href ? "primary" : "secondary"}
                sx={{
                  width: { md: "32px" },
                  height: { md: "48px" },
                  ".dogface_svg__color": { fill: href === value ? tan[300] : "white" },
                }}
                inheritViewBox
                component={icon}
              />
            }
          />
        ))}
      </BottomNavigation>
    </Paper>
  );
};

export default BottomNav;
