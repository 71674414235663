import { useUser } from "@clerk/nextjs";
import { MenuItem, Popper, Tab, TabProps, Typography } from "@mui/material";
import Link from "next/link";
import { useState } from "react";
import { orange } from "./Theme";

export interface TabMenuProps extends TabProps {
  href: string;
  label: string;
  options?: { label: string; href: string }[];
  imageUrl?: string;
}

const TabMenuDropdown = ({ href, label, options }: TabMenuProps) => {
  const { user } = useUser();
  const isSignedIn = !!user;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  let lock;
  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    lock = setTimeout(() => setAnchorEl(null), 200);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  const handleRedirect = (event) => {
    window.location.href = event.target.value;
  };
  return (
    <>
      <Tab
        key={href}
        label={label}
        href={href}
        LinkComponent={Link}
        onMouseOver={handleOpen}
        onMouseLeave={handleClose}
        sx={{ marginRight: { md: 0, lg: "20px" }, marginLeft: { md: 0, lg: "20px" } }}
      />
      {options && (
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          onMouseOver={() => {
            clearTimeout(lock);
          }}
          onMouseLeave={(e) => {
            handleClose(e);
          }}
          sx={{ zIndex: 10000, backgroundColor: orange[15] }}
        >
          {options.map(({ href, label }, index) => (
            <MenuItem key={index} sx={{ width: "100%", padding: "10px", display: isSignedIn ? "block" : "none" }}>
              <Link href={href}>
                <Typography variant="body2">{label}</Typography>
              </Link>
            </MenuItem>
          ))}
        </Popper>
      )}
    </>
  );
};

export default TabMenuDropdown;
