import { Close } from "@mui/icons-material";
import { Box, ButtonBase, IconButton, ListItemIcon, Menu, MenuItem, SvgIcon, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import Link from "next/link";
import { useEffect, useState } from "react";
import { HiChat } from "react-icons/hi";
import { IoMdNotifications } from "react-icons/io";
import { breederIcon, dogface, litterIcon } from "../public/assets/icons";
import { tan } from "./Theme";
import UnreadCounter from "./UnreadCounter";
import { fetchMarkNotificationsReadEndpoint, useGetNotificationsEndpoint } from "./generated/happydogsComponents";
import { Notification } from "./generated/happydogsSchemas";

interface NotificationProps {
  notification: Notification;
  memberId?: string;
  refetch: () => unknown;
}

const NotificationElement = ({ notification, memberId, refetch }: NotificationProps) => {
  dayjs.extend(relativeTime);

  const setIcon = (category: string) => {
    switch (category) {
      case "Dog":
        return (
          <ListItemIcon sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "70px", width: "70px" }}>
            <SvgIcon inheritViewBox component={dogface} color="primary" fontSize="large" />{" "}
          </ListItemIcon>
        );
      case "Breeder":
        return (
          <ListItemIcon sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "70px", width: "70px" }}>
            <SvgIcon inheritViewBox color="primary" component={breederIcon} sx={{ height: "70px" }} fontSize="large" />{" "}
          </ListItemIcon>
        );
      case "Litter":
        return (
          <ListItemIcon>
            <SvgIcon inheritViewBox color="primary" component={litterIcon} fontSize="large" />{" "}
          </ListItemIcon>
        );
      default:
        <></>;
    }
  };

  const notificaionRead = async () => {
    if (!memberId) {
      console.error("Unable to mark notification as read without memberId!");
      return;
    }
    try {
      await fetchMarkNotificationsReadEndpoint({ body: { notificationId: notification.id, memberId } });
      refetch();
    } catch (e) {
      console.error("Failed to submit read notification", e);
    }
  };

  return (
    <Box display={"flex"} overflow={"hidden"} borderBottom={"1px solid #cec5c5"} width={"100%"}>
      {notification.profilePictureUrl ? <img height="70px" src={notification.profilePictureUrl} /> : setIcon(notification.category)}
      <MenuItem sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
        <Box
          width={"100%"}
          display="flex"
          pr="10px"
          component={Link}
          href={notification.link}
          sx={{ color: "text.primary", textDecoration: "none" }}
          justifyContent={"space-between"}
        >
          <Box>
            <Typography variant="subtitle1">{notification.message}</Typography>
            <Typography> {dayjs(notification.sentAt).fromNow()}</Typography>
          </Box>
        </Box>
        <IconButton onClick={() => notificaionRead()} sx={{ justifySelf: "end" }}>
          <Close sx={{ fontSize: "15px" }} />
        </IconButton>
      </MenuItem>
    </Box>
  );
};

interface NotificationMenuProps {
  memberId?: string;
}

const NotificationMenu = ({ memberId }: NotificationMenuProps) => {
  const isMobile = useMediaQuery("(max-width:1200px)");

  const { data: notifications, refetch } = useGetNotificationsEndpoint({ pathParams: { memberId: memberId! } }, { enabled: !!memberId });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const fetching = setInterval(refetch, 60000);
    return clearInterval(fetching);
  }, []);

  return (
    <>
      <IconButton
        onClick={handleClick}
        size="small"
        aria-controls={open ? "account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      >
        <IoMdNotifications color={tan[300]} style={{ fontSize: isMobile ? "30px" : "40px" }} />
        {notifications?.notifications && notifications?.notifications.length > 0 && (
          <UnreadCounter amount={notifications?.notifications.length} />
        )}
      </IconButton>
      <ButtonBase
        href={
          notifications?.messages && notifications.messages.length > 0
            ? `/me/messages/${notifications?.messages[0].threadId}`
            : "/me/messages"
        }
        LinkComponent={Link}
      >
        <HiChat color={tan[300]} style={{ fontSize: isMobile ? "30px" : "40px" }} />
        {notifications?.messages && notifications?.messages.length > 0 && <UnreadCounter amount={notifications?.messages.length} />}
      </ButtonBase>
      {notifications?.notifications && notifications?.notifications.length > 0 && (
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          MenuListProps={{ sx: { padding: 0 } }}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          {notifications?.notifications
            // @ts-ignore
            .toReversed()
            .map((notification: Notification) => (
              <NotificationElement key={notification.id} refetch={refetch} notification={notification} memberId={memberId} />
            ))}
        </Menu>
      )}
    </>
  );
};

export default NotificationMenu;
