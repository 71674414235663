import { Dispatch, SetStateAction, useState } from "react";
import CustomDrawer from "../Dialog/CustomDrawer";
import { HandleAsyncFunctionWithToast } from "../Toasts";
import { useSendBugRequestEndpoint, useSendSuggestionEndpoint, useSendSupportRequestEndpoint } from "../generated/happydogsComponents";
import FeedBackSelection from "./FeedBackselection";
import ReportModal from "./ReportModal";

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const FeedBackDrawer = ({ open, setOpen }: Props) => {
  const [activeTab, setActiveTab] = useState(0);

  const SupportMutation = useSendSupportRequestEndpoint();
  const BugMutation = useSendBugRequestEndpoint();
  const SuggestionMutation = useSendSuggestionEndpoint();

  const sendSupportReport = HandleAsyncFunctionWithToast(async (message: string) => {
    await SupportMutation.mutateAsync({
      body: {
        content: message,
      },
    });
    setOpen(false);
  });

  const sendBugReport = HandleAsyncFunctionWithToast(async (message: string) => {
    await BugMutation.mutateAsync({
      body: {
        content: message,
      },
    });
    setOpen(false);
    setActiveTab(0);
  });
  const sendSuggestion = HandleAsyncFunctionWithToast(async (message: string) => {
    await SuggestionMutation.mutateAsync({
      body: {
        content: message,
      },
    });
    setOpen(false);
    setActiveTab(0);
  });

  const Tabs = [
    <FeedBackSelection bugReport={() => setActiveTab(2)} supportReport={() => setActiveTab(1)} Suggestionreport={() => setActiveTab(3)} />,
    <ReportModal
      onClose={() => setActiveTab(0)}
      onSend={sendSupportReport}
      title={"Be om support"}
      helpertext={"Forklar hva du trenger hjelp til."}
    />,
    <ReportModal
      onClose={() => setActiveTab(0)}
      onSend={sendBugReport}
      title={"Rapporter feil"}
      helpertext={"Er det noe som ikke fungerer som det skal? Skriv det her så skal vi fikse det!"}
    />,
    <ReportModal
      onClose={() => setActiveTab(0)}
      onSend={sendSuggestion}
      title={"Send inn et forslag"}
      helpertext={"Hva skal til for at Happydogs blir enda bedre? Send inn ditt forslag, så blir det kanskje det neste vi utvikler!"}
    />,
  ];

  return (
    <CustomDrawer open={open} setOpen={setOpen}>
      {Tabs[activeTab]}
    </CustomDrawer>
  );
};

export default FeedBackDrawer;
