import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/providers.tsx");
;
import(/* webpackMode: "eager" */ "/app/components/Cookiebar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/LandingPage/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/Reporting/ReportButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/ResponsiveNavBar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ClientClerkProvider"] */ "/app/node_modules/@clerk/nextjs/dist/esm/app-router/client/ClerkProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["AuthenticateWithRedirectCallback","ClerkLoaded","ClerkLoading","MultisessionAppSupport","RedirectToCreateOrganization","RedirectToOrganizationProfile","RedirectToSignIn","RedirectToSignUp","RedirectToUserProfile"] */ "/app/node_modules/@clerk/nextjs/dist/esm/client-boundary/controlComponents.js");
;
import(/* webpackMode: "eager", webpackExports: ["EmailLinkErrorCode","MagicLinkErrorCode","WithClerk","WithSession","WithUser","isClerkAPIResponseError","isEmailLinkError","isKnownError","isMagicLinkError","isMetamaskError","useAuth","useClerk","useEmailLink","useMagicLink","useOrganization","useOrganizationList","useOrganizations","useSession","useSessionList","useSignIn","useSignUp","useUser","withClerk","withSession","withUser"] */ "/app/node_modules/@clerk/nextjs/dist/esm/client-boundary/hooks.js");
;
import(/* webpackMode: "eager", webpackExports: ["CreateOrganization","GoogleOneTap","OrganizationList","OrganizationProfile","OrganizationSwitcher","SignIn","SignInButton","SignInWithMetamaskButton","SignOutButton","SignUp","SignUpButton","UserButton","UserProfile"] */ "/app/node_modules/@clerk/nextjs/dist/esm/client-boundary/uiComponents.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material-nextjs/v13-appRouter/appRouterV13.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@mui/material/Box/Box.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/app/styles/globals.css");
