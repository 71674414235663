"use client";
import { SignOutButton, useUser } from "@clerk/nextjs";
import { AdminPanelSettings, ExpandLess, ExpandMore, Logout, PersonAdd, Settings, SupportAgent } from "@mui/icons-material";
import { Avatar, Box, Button, ButtonBase, Divider, ListItemIcon, Menu, MenuItem, Typography } from "@mui/material";
import Link from "next/link";
import { MouseEventHandler, useState } from "react";
import FeedBackDrawer from "./Reporting/FeedBackDrawer";
import { orange, tan } from "./Theme";
import { useGetPrivateMemberDetailsEndpoint } from "./generated/happydogsComponents";
import { Setting } from "clarity-js/types/data";

const ProfileDropdown = () => {
  const { data: member } = useGetPrivateMemberDetailsEndpoint({});
  const [icon, setIcon] = useState(<ExpandMore fontSize="large" color="secondary" />);

  const [showReport, setShowReport] = useState(false);

  const isAdmin = member?.roles?.includes("Admin") || member?.roles?.includes("SuperAdmin");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Box onClick={handleClick} style={{ display: "flex", justifyContent: "center", alignItems: "center", justifySelf: "end" }}>
        <Avatar
          sx={{ width: "44px", height: "44px" }}
          alt={member?.name}
          src={member?.profilePicture?.url}
          style={{ border: "1px solid #E55807" }}
        />
        {icon}
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        MenuListProps={{
          sx: {
            ".MuiMenuItem-root": {
              pt: "10px",
              pb: "10px",
              pl: "25px",
              pr: "25px",
            },
          },
        }}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              bgcolor: orange[15],
              mt: 1.5,
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: orange[15],
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem component={Link} href="/me">
          <ListItemIcon>
            <Settings color="secondary" fontSize="small" />
          </ListItemIcon>
          Min side
        </MenuItem>
        <MenuItem onClick={() => setShowReport(true)}>
          <ListItemIcon>
            <SupportAgent color="secondary" fontSize="small" />
          </ListItemIcon>
          Hjelp
        </MenuItem>
        {isAdmin && (
          <MenuItem component={Link} href={"/admin/verify-dogs"}>
            <ListItemIcon>
              <AdminPanelSettings color="secondary" fontSize="small" />
            </ListItemIcon>
            Admin
          </MenuItem>
        )}
        <Divider />
        <MenuItem>
          <SignOutButton>
            <Link href="/" style={{ display: "flex" }}>
              <ListItemIcon>
                <Logout color="secondary" fontSize="small" />
              </ListItemIcon>
              Logg ut
            </Link>
          </SignOutButton>
        </MenuItem>
      </Menu>
      <FeedBackDrawer open={showReport} setOpen={setShowReport} />
    </>
  );
};

export default ProfileDropdown;
