"use client";

import { Box, Button, Container, TextField, Typography } from "@mui/material";
import { FormEvent, useState } from "react";

interface Modalprops {
  onClose: () => void;
  onSend: (message: string) => Promise<void | null>;
  title: string;
  helpertext: string;
}

const ReportModal = ({ onClose, onSend, title, helpertext }: Modalprops) => {
  const [content, setContent] = useState("");

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.currentTarget.reportValidity();
    onSend(content);
  };

  return (
    <Container maxWidth="sm" sx={{ display: "flex", flexDirection: "column", gap: "30px" }} component={"form"} onSubmit={handleSubmit}>
      <Typography variant="h3">{title}</Typography>

      <TextField
        required
        multiline
        autoFocus
        rows={5}
        value={content}
        margin="dense"
        placeholder={helpertext}
        type="text"
        fullWidth
        onChange={(e) => setContent(e.target.value)}
      />
      <Box display={"flex"} gap="5px" alignSelf={"end"}>
        <Button type="reset" onClick={onClose} sx={{ alignSelf: "end" }} variant="outlined" color="secondary">
          Tilbake
        </Button>
        <Button type="submit" variant="contained">
          Send
        </Button>
      </Box>
    </Container>
  );
};

export default ReportModal;
