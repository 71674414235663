import { Clear } from "@mui/icons-material";
import { Box, ButtonBase, Drawer, DrawerProps as MuiDrawerProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Dispatch, SetStateAction } from "react";
import { orange, tan } from "../Theme";

const Puller = styled("div")(({ theme }) => ({
  width: 80,
  height: 6,
  backgroundColor: tan[300],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 40px)",
}));

interface DrawerProps extends MuiDrawerProps {
  buttonRef?: React.RefObject<HTMLButtonElement>;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const CustomDrawer = ({ open, setOpen, children, buttonRef, ...other }: DrawerProps) => {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Drawer
      onClose={handleClose}
      anchor={"bottom"}
      open={open}
      PaperProps={{
        elevation: 0,
        style: { borderRadius: "20px 20px 0 0", backgroundColor: orange[15], width: "100%", maxHeight: "90%" },
      }}
      {...other}
    >
      <ButtonBase
        ref={buttonRef} // This could do weird things, but will work specifically for scrolling into view.
        onClick={handleClose}
        sx={{ alignSelf: "start", padding: "10px" }}
      >
        <Clear color="secondary" fontSize="large" />
      </ButtonBase>

      <Box sx={{ display: "flex", gap: "50px", flexDirection: "column", textAlign: "center", marginTop: "50px", marginBottom: "50px" }}>
        <Puller />
        {children}
      </Box>
    </Drawer>
  );
};

export default CustomDrawer;

export const DrawerButton = styled(ButtonBase)`
  width: 100%;
  background-color: ${orange[70]};
  color: ${tan[300]};
  height: 65px;
  border-bottom: 1px solid ${tan[300]};
  text-align: left;
`;
