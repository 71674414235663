import { Box, Typography } from "@mui/material";

interface CounterProps {
  amount: number;
}

const UnreadCounter = ({ amount }: CounterProps) => {
  return (
    <Box
      sx={{
        backgroundColor: "#990f0c",
        paddingRight: { md: "8px", xs: "5px" },
        paddingLeft: { md: "8px", xs: "5px" },
        paddingTop: { md: "1px", xs: "0px" },
        paddingBottom: { md: "1px", xs: "0px" },
        borderRadius: "100px",
        position: "absolute",
        bottom: 0,
        right: -5,
      }}
    >
      <Typography variant="subtitle2" color={"white.main"}>
        {amount}
      </Typography>
    </Box>
  );
};

export default UnreadCounter;
