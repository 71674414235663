import { useSelectedLayoutSegments } from "next/navigation";
import { useMemo } from "react";
import { breederIcon, dogface, explore, puppyLog, waitinglistIcon } from "../../public/assets/icons";

const useMenuItems = (isBreeder: boolean, hasBreederPage: boolean) => {
  const segments = useSelectedLayoutSegments();

  const menuItems = useMemo(() => {
    switch (segments[0]) {
      case "admin":
        return [
          { label: "Verifiser hund", href: "/admin/verify-dogs" },
          { label: "Bugs", href: "/admin/bugs" },
          { label: "Administrer artikler", href: "/admin/articles" },
          { label: "Medlemmer", href: "/admin/members" },
        ];
      default:
        return isBreeder
          ? [
              {
                label: "Oppdrett",
                href: "/me/breeder",
                icon: breederIcon,
                options: hasBreederPage
                  ? [
                      { label: "Mitt oppdrett", href: "/me/breeder" },
                      { label: "Mine hunder", href: "/me/dogs" },
                      { label: "Mine kull", href: "/me/litters" },
                    ]
                  : [],
              },
              {
                label: "Utforsk",
                icon: explore,
                href: "/search?type=Breeder",
                options: [
                  { label: "Oppdrettere", href: "/search?type=Breeder" },
                  { label: "Hunder", href: "/search?type=Dog" },
                  { label: "Valpekull", href: "/search?type=Litter" },
                ],
              },
              {
                label: "Logg",
                href: "/me/log",
                icon: puppyLog,
                options: [
                  { label: "Valpelogg", href: "/me/log/puppylog" },
                  { label: "Hundelogg", href: "/me/log/doglog" },
                ],
              },
              { label: "Venteliste", href: "/me/waitinglist", icon: waitinglistIcon },
            ]
          : [
              {
                label: "Mine hunder",
                href: "/me/dogs",
                icon: dogface,
                options: [
                  { label: "Mine hunder", href: "/me/dogs" },
                  { label: "Legg til hund", href: "/me/add-dog" },
                ],
              },
              {
                label: "Utforsk",
                href: "/search",
                icon: explore,
                options: [
                  { label: "Hunder", href: "/search?type=Dog" },
                  { label: "Valpekull", href: "/search?type=Litter" },
                  { label: "Oppdrettere", href: "/search?type=Breeder" },
                ],
              },
              { label: "Logg", href: "/me/log/doglog", icon: puppyLog },
              { label: "Søknader", href: "/me/applications", icon: waitinglistIcon },
            ];
    }
  }, [isBreeder, hasBreederPage, segments]);

  return menuItems;
};

export default useMenuItems;
