"use client";

import { ThemeProvider, useTheme } from "@emotion/react";
import { createTheme, useMediaQuery } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Link from "next/link";
import { useSelectedLayoutSegments } from "next/navigation";
import * as React from "react";
import { useEffect, useState } from "react";
import useMenuItems from "../CustomHooks/useMenuitems";
import TabMenuDropdown, { TabMenuProps } from "../TabMenuDropdown";
import { tan } from "../Theme";

interface MenuProps {
  isBreeder?: boolean;
  hasBreederPage?: boolean;
}

const HorizontalMenu = ({ isBreeder = false, hasBreederPage = false }: MenuProps) => {
  const segments = useSelectedLayoutSegments();

  const menuItems = useMenuItems(isBreeder, hasBreederPage);

  const [value, setValue] = useState<string | boolean>(false);

  const theme = useTheme();

  useEffect(() => {
    setValue(() => {
      switch (segments[0]) {
        case "me":
          if (!segments[1]) {
            return false; // Should this be false instead? @Anne Skjaeveland (If a user is on the profile, this will put the tab under "Oppdrett", setting to false will remove the tab underline)
          } else {
            switch (segments[1]) {
              case "messages":
                return false;
              case "(protected)":
                return "/" + segments[0] + "/" + segments[2];
              default:
                return "/" + segments[0] + "/" + segments[1];
            }
          }
        case "admin":
          return false;
        case "search":
          return "/search?type=Breeder";
        default:
          return false;
      }
    });
  }, [segments]);

  const newTheme = createTheme(theme, {
    components: {
      MuiTab: {
        styleOverrides: {
          root: {
            color: tan[300],
            backgroundColor: "transparent",
            alignItems: "center",
            paddingBottom: "20px",
            borderRadius: 0,
            fontFamily: "Inter",
            fontSize: "16px",
            fontWeight: 600,
            textTransform: "none",
            opacity: 1,
            "@media (max-width:900px)": {
              alignItems: "center",
            },
            "&.Mui-selected": {
              backgroundColor: "transparent",
              color: tan[300],
              "@media (max-width:900px)": {
                alignItems: "center",
              },
            },
          },
        },
      },
    },
  });

  const largeScreen = useMediaQuery(newTheme.breakpoints.up("md"));

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <ThemeProvider theme={newTheme}>
      <Tabs value={value} onChange={handleChange} centered sx={{ paddingTop: "5px" }}>
        {menuItems.map(({ label, href, options }: TabMenuProps, index) => (
          <TabMenuDropdown key={href} LinkComponent={Link} value={href} href={href} label={label} options={options} />
        ))}
      </Tabs>
    </ThemeProvider>
  );
};
export default HorizontalMenu;
